// 个人中心
<template>
  <div class="my">
    <el-image class="wh100" :src="require('@/assets/images/grzx-bn1.png')"></el-image>
    <div class="content">
      <el-tabs v-model="activeName" class="my-navs">
        <el-tab-pane label="会员信息" name="first">
          <el-form :model="userFrom" :rules="rules" ref="userFrom" label-position="right" label-width="auto"
            class="my-userFrom">
            <el-form-item label="姓名" prop="name">
              <el-input v-model="userFrom.name" placeholder="请输入您的姓名"></el-input>
            </el-form-item>
            <el-form-item label="性别" prop="sex">
              <el-radio-group v-model="userFrom.sex">
                <el-radio :label="1">男</el-radio>
                <el-radio :label="0">女</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="手机号" prop="phone">
              <el-input v-model="userFrom.phone" disabled autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="邮箱" prop="email">
              <el-input v-model="userFrom.email" placeholder="请输入您的邮箱账号"></el-input>
            </el-form-item>
            <el-form-item class="form-submit">
              <el-button type="primary" @click="submitForm('userFrom')">保 存</el-button>
            </el-form-item>
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="知识课堂" name="second">
          <el-tabs v-model="classroomName" class="classroom-navs">
            <el-tab-pane v-for="(item,index) in classroom" :key="index" :label="item.title" :name="item.key">
              <div v-html="item.text"></div>
            </el-tab-pane>
          </el-tabs>
        </el-tab-pane>
        <el-tab-pane label="会员福利" name="third">
          <h2 style="font-size:26px; text-align:center">会员福利</h2>
          <el-row class="welfare" :gutter="20">
            <el-col :span="12" v-for="(item,index) in welfare" :key="index">
              <div class="welfare-item align-center">
                <i>0{{index+1}}</i>
                <p>{{item}}</p>
                <el-image :src="require('@/assets/images/my-img1.png')"></el-image>
              </div>
            </el-col>
          </el-row>
        </el-tab-pane>
      </el-tabs>

    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import { updateUser } from '@/api'
import { classrooms } from '@/api/classroom.js'
export default {
  name: 'Cooperation',
  computed: {
    ...mapGetters(['getUser'])
  },
  data () {
    return {
      activeName: 'first',
      classroomName: 'first',
      userFrom: {
        name: '',
        sex: 1,
        phone: '',
        email: ''
      },
      rules: {
        name: {},
        email: [
          { type: 'email', message: '请正确输入邮箱', trigger: 'blur' },
        ]
      },
      classroom: classrooms,
      welfare: ['专利、商标和著作权申请登记等基础业务免费咨询', '受邀参加中金浩组织的系列知识产权沙龙活动', '知识产权相关政策免费咨询及分析', '无形资产评估专享8折优惠券（仅限1次）'],
    };
  },
  mounted () {
    this.updateUser(this.getUser.user)
  },
  methods: {
    updateUser (data) {
      for (const key in this.userFrom) {
        this.userFrom[key] = data[key]
      }
    },
    // 保存
    submitForm (formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          // 删除phone属性
          let userFromCopy = JSON.parse(JSON.stringify(this.userFrom))
          this.$delete(userFromCopy, 'phone')

          let { status, msg, data } = await updateUser(userFromCopy)
          if (status == 200) {
            this.$store.dispatch('setUser', { token: this.getUser.token, user: data })
            this.updateUser(data)
            this.$message.success(msg);
          } else {
            this.$message.error(msg);
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    }
  },
};
</script>

<style scoped lang="less">
/deep/ .el-tabs__nav-wrap {
  &::after {
    display: none;
  }
}
/deep/ .el-tabs__content {
  margin-top: 50px;
}

/deep/.my-navs {
  & > .el-tabs__header {
    .el-tabs__nav-wrap {
      .el-tabs__item {
        font-size: 18px;
      }
    }
  }
}

// 会员信息
/deep/ .my-userFrom {
  width: 500px;
  margin: 0 auto;
  .el-input {
    border-radius: 10px;
  }
  .el-input__inner,
  .el-radio__label {
    font-size: 16px;
  }
  .form-submit {
    text-align: center;
  }
  input::-webkit-input-placeholder,
  input::-moz-input-placeholder,
  input::-ms-input-placeholder {
    font-size: 14px;
  }
}

// 知识课堂
/deep/ .classroom-navs {
  .el-tabs__active-bar {
    display: none;
  }
  .el-tabs__nav {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .el-tabs__item {
    padding: 10px !important;
    border: 1px solid #409eff;
    border-radius: 10px;
    text-align: center;
    height: auto;
    line-height: inherit;
    &.is-active {
      background-color: #409eff;
      color: #fff;
    }
  }
}

// 会员福利
.welfare {
  margin-top: 50px;
  .welfare-item {
    cursor: pointer;
    height: 100px;
    text-align: left;
    margin: 10px 0;
    padding: 20px;
    background-color: #fff;
    position: relative;
    transition: all 0.2s;
    overflow: hidden;
    &::before {
      content: "";
      width: 3px;
      height: 100%;
      background-color: #1b92ff;
      position: absolute;
      top: 0;
      left: 0;
    }
    &:hover {
      background-color: #1bb9ff;
      p {
        color: #fff;
      }
      i {
        color: #1bb0f1;
      }
      .el-image {
        opacity: 0;
      }
    }
    p {
      transition: all 0.2s;
      font-size: 20px;
      line-height: 100px;
    }
    i {
      position: absolute;
      top: 10px;
      right: 10px;
      color: #e2f2fe;
      font-size: 65px;
      font-style: normal;
      font-weight: bold;
      transition: all 0.2s;
    }
    .el-image {
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 3px;
      opacity: 1;
      transition: all 0.2s;
    }
  }
}
</style>
